import { Type, type Static } from '@sinclair/typebox';
import { useQuery } from '@tanstack/vue-query';
import { validatorFactory } from '@laam/lib/validator';
import * as Sentry from '@sentry/vue';

const shippingDateEstimatorSchema = Type.Object({
	handling_date: Type.String(),
});

export type ShippingDatesEstimator = Static<typeof shippingDateEstimatorSchema>;

const shippingDatesEstimatorValidator =
	validatorFactory<ShippingDatesEstimator>(shippingDateEstimatorSchema);

export const useShippingDatesEstimator = (variantId: Ref<string>) => {
	const enabled = computed(() => variantId.value != '');
	const { data, isLoading, suspense, error, refetch } =
		useQuery<ShippingDatesEstimator>({
			queryKey: ['variantId', variantId],
			staleTime: Infinity,
			gcTime: Infinity,
			queryFn: async () => {
				const response = await fetch(
					`https://api.laam.pk/handling_timelines/?product_variant_id=${variantId.value}`,
				);
				if (response.status === 200) {
					const body = await response.json();
					try {
						return shippingDatesEstimatorValidator.verify(body);
					} catch (err) {
						const error = new Error(
							'Failed to verify shipping dates estimator data',
						);
						Sentry.captureException(error, (scope) => {
							scope.setContext('errors', {
								error: err,
							});
							return scope;
						});
						console.error(
							'Failed to verify shipping dates estimator data',
							err,
						);
						throw error;
					}
				}
				const error = new Error(
					'Failed to fetch shipping dates estimator data',
				);
				const data = await response.json();
				Sentry.captureException(error, (scope) => {
					scope.setContext('response', data);
					scope.setContext('payload', { variantId: variantId.value });
					return scope;
				});
				throw error;
			},
			enabled: enabled,
		});
	return { data, isLoading, suspense, error, refetch };
};
