import { Type, type Static } from '@sinclair/typebox';

export const Image = Type.Object({
	src: Type.String(),
	id: Type.String(),
	height: Type.Number(),
	width: Type.Number(),
	transformedSrc: Type.String(),
});

export const PriceV2 = Type.Object({
	amount: Type.Any(),
	currencyCode: Type.String(),
});

export const SelectedOption = Type.Object({
	name: Type.String(),
	value: Type.String(),
});

export const Product = Type.Object({
	title: Type.String(),
	handle: Type.String(),
	id: Type.String(),
	tags: Type.Array(Type.String()),
	vendor: Type.Optional(Type.String()),
	retailer_brand: Type.String(),
	attributes: Type.Optional(
		Type.Object({
			'product-type': Type.String(),
			'color-type': Type.String(),
			season: Type.String(),
			fabric: Type.String(),
			'shirt-fabrics': Type.String(),
			'trouser-fabrics': Type.String(),
			'bottom-fabrics': Type.String(),
			'dupatta-fabrics': Type.String(),
			'number-of-pieces': Type.String(),
		}),
	),
	node_l1: Type.Optional(Type.String()),
	node_l2: Type.Optional(Type.String()),
	node_l3: Type.Optional(Type.String()),
	node_l4: Type.Optional(Type.String()),
});

export const ProductVariant = Type.Object({
	id: Type.String(),
	sku: Type.String(),
	title: Type.String(),
	quantityAvailable: Type.Number(),
	compareAtPriceV2: Type.Any(PriceV2),
	image: Image,
	priceV2: PriceV2,
	selectedOptions: Type.Array(SelectedOption),
	product: Product,
});

export const CartLine = Type.Object({
	id: Type.String(),
	quantity: Type.Number(),
	attributes: Type.Array(
		Type.Object({
			key: Type.String(),
			value: Type.String(),
		}),
	),
	merchandise: ProductVariant,
	new_price: Type.Optional(Type.Number()),
});

export const Cost = Type.Object({
	checkoutChargeAmount: PriceV2,
	subtotalAmount: PriceV2,
	totalAmount: PriceV2,
	totalDutyAmount: Type.Null(),
	totalTaxAmount: Type.Null(),
});

export const HVCStatus = Type.Any();

// export const Nodes = Type.Object({
// 	nodes: Type.Array(Type.Undefined()),
// });

export const Lines = Type.Union([Type.Null(), Type.Array(CartLine)]);

export const Data = Type.Object({
	checkoutUrl: Type.String(),
	id: Type.String(),
	note: Type.Optional(Type.String()), // Make note optional
	totalQuantity: Type.Number(),
	cost: Cost,
	lines: Lines,
	hvc_status: Type.Optional(HVCStatus),
});

export const Response = Type.Object({
	msg: Type.String(),
	code: Type.Number(),
	data: Data,
});

// Exporting the static type
export type CartResponse = Static<typeof Response>;
export type Lines = Static<typeof Lines>;
export type CartDataType = Static<typeof Data>;
